<template>
  <v-card
    v-if="tenantGroup || allTenantsMode"
    max-width="1200"
    flat
  >
    <div
      v-if="allTenantsMode"
      class="title grey--text text--lighten-1 font-weight-light"
      style="align-self: center;"
    >
      {{ $t("Select a Company") }}
    </div>
    <div v-else>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col cols="12">
            <v-card-title class="pb-0">
              {{ title }}
              <v-spacer />
              <v-card-actions>
                <v-spacer />
                <delete-tenant-group-button
                  v-if="canDeleteTenantGroup"
                  class="ml-3 mr-3"
                  :tenant-group-id="id"
                  :name="title"
                  @delete="$emit('delete')"
                />
                <v-btn
                  v-if="canEditTenantGroup"
                  color="success"
                  @click="saveChanges"
                >
                  <v-icon left>
                    mdi-check-circle-outline
                  </v-icon>
                  {{ $t('Save Changes') }}
                </v-btn>
              </v-card-actions>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col>
                  <div class="subtitle-1">
                    <v-text-field
                      v-model="name"
                      prepend-icon="mdi-account-circle-outline"
                      :label="$t('Name')"
                      :readonly="!canEditTenantGroup"
                      :rules="nameRules"
                      outlined
                      dense
                    />
                  </div>
                  <div class="my-2 subtitle-1">
                    <v-text-field
                      v-model="description"
                      prepend-icon="mdi-comment-text-outline"
                      :label="$t('Description')"
                      :readonly="!canEditTenantGroup"
                      :rules="descriptionRules"
                      outlined
                      dense
                    />
                  </div>
                  <div class="my-2 subtitle-1">
                    <v-text-field
                      v-model="code"
                      prepend-icon="mdi-qrcode"
                      :label="$t('Code')"
                      :readonly="!canEditTenantGroup"
                      :rules="codeRules"
                      outlined
                      dense
                    />
                  </div>
                </v-col>
                <v-col>
                  <div class="subtitle-1">
                    <search-address
                      :address="address"
                      :readonly="!canEditTenantGroup"
                      @select="address = $event"
                    />
                  </div>
                  <div class="my-2 subtitle-1">
                    <v-text-field
                      v-model="contactName"
                      prepend-icon="mdi-account-circle-outline"
                      :label="$t('Contact Name')"
                      :readonly="!canEditTenantGroup"
                      outlined
                      dense
                    />
                  </div>
                  <div class="my-2 subtitle-1">
                    <v-autocomplete
                      v-model="selectedTenants"
                      prepend-icon="mdi-store-outline"
                      :items="tenantsListHere"
                      :readonly="!canEditTenantGroup"
                      item-text="name"
                      item-value="id"
                      return-object
                      dense
                      outlined
                      label="Selected Companies"
                      multiple
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <UserDirectoryTenantGroups
                    :id="id"
                    :is-system-admin="isSystemAdmin"
                    :can-edit-tenant="canEditTenantGroup"
                    :is-tenant-group="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import UserDirectoryTenantGroups from '@/components/tenant-groups/TenantGroupDirectoryUser.vue'
import DeleteTenantGroupButton from '@/components/tenant-groups/TenantGroupButtonDelete.vue'
import SearchAddress from '@/components/common/SearchAddress.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'InfoCard',
  components: {
    UserDirectoryTenantGroups,
    DeleteTenantGroupButton,
    SearchAddress
  },
  props: {
    id: {
      type: String,
      required: true
    },
    isSystemAdmin: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      selectedTenants: [],
      title: '',
      name: '',
      description: '',
      code: '',
      address: '',
      contactName: '',
      tenantGroup: null,
      canEditTenantGroup: false,
      canDeleteTenantGroup: false,
      tenantsListHere: [],
      allTenantsMode: false,
      nameRules: [v => !!v || 'Please fill in this field'],
      descriptionRules: [],
      codeRules: [v => !!v || 'Please fill in this field']
    }
  },
  computed: {
    ...mapGetters(['tenantsList', 'userName'])
  },
  async created () {
    this.loading = true
    await this.getTenantGroup()
    await this.setPermissions()
    console.log('tenantsList:', this.tenantsList)
    this.tenantsListHere = this.tenantsList.filter(el => el.id !== 'system')
    console.log('selectedTenants:', this.selectedTenants)

    this.loading = false
  },
  methods: {
    async setPermissions () {
      this.canEditTenantGroup = await this.enforce('tenant-registry', 'tenant-group-edit', this.userName, this.id)
      this.canDeleteTenantGroup = await this.enforce('tenant-registry', 'tenant-group-delete', this.userName, 'system')
    },
    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      const enforce = await this.$store.dispatch('enforce', {
        userName,
        tenantId,
        entity,
        action
      })
      this.loading = false
      return enforce
    },
    async getTenantGroup () {
      if (this.id === '1') {
        this.allTenantsMode = true
        return
      }
      const data = await this.$store.dispatch('getTenantGroup', {
        id: this.id
      })
      const tenantsIdList = data.tenants ? data.tenants.split(',') : ''
      console.log('[getTenantGroup] tenantsIdList:', tenantsIdList)
      this.selectedTenants = []
      for (const tenantId of tenantsIdList) {
        this.selectedTenants.push({ id: tenantId, tenantId: tenantId, name: '', entity_type: 'TENANT' })
      }
      this.tenantGroup = data
      this.title = data.name
      this.name = data.name
      this.description = data.description
      this.code = data.code
      this.address = data.address
      this.contactName = data.contactName
    },
    saveChanges () {
      console.log(this.selectedTenants)
      const tenants = this.selectedTenants.filter(el => el.id.startsWith('TENANT')).map(el => el.id).join(',')
      console.log('tenants:', tenants)
      if (!this.$refs.form.validate()) {
        return null
      }
      this.$store
        .dispatch('editTenantGroup', {
          id: this.id,
          name: this.name,
          description: this.description,
          attributes: [
            { name: 'name', value: this.name },
            { name: 'contactName', value: this.contactName },
            { name: 'code', value: this.code },
            { name: 'address', value: this.address },
            { name: 'tenants', value: tenants }
          ]
        })
        .then((data) => {
          this.$store.commit('showMessage', { message: `${this.title} updated successfully`, color: 'success' })
        })
        .then(() => {
          this.getTenantGroup()
          this.$emit('change')
        })
      // if (this.name !== this.title) {
      //   this.$store.dispatch('fetchTenantGroups')
      // }
    }
  }
}
</script>
