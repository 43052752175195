<template>
  <v-card
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t('Save Changes') }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Name')"
                    :rules="nameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="description"
                    prepend-icon="mdi-comment-text-outline"
                    :label="$t('Description')"
                    :rules="descriptionRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="code"
                    prepend-icon="mdi-qrcode"
                    :label="$t('Code')"
                    :rules="codeRules"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="address"
                    prepend-icon="mdi-map-marker-outline"
                    :label="$t('Address')"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="contactName"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Contact Name')"
                    outlined
                    dense
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NewTenantGroupCard',
  data () {
    return {
      valid: false,
      title: 'New Company Group',
      name: '',
      description: '',
      code: '',
      address: '',
      contactName: '',
      nameRules: [v => !!v || 'Please fill in this field'],
      descriptionRules: [],
      codeRules: [v => !!v || 'Please fill in this field']
    }
  },
  computed: {
    ...mapGetters(['userName'])
  },
  methods: {
    async saveChanges () {
      if (!this.$refs.form.validate()) {
        return null
      }
      try {
        const newTenantGroup = await this.$store
          .dispatch('createTenantGroup', {
            name: this.name,
            description: this.description,
            attributes: [
              { name: 'description', value: this.description },
              { name: 'address', value: this.address },
              { name: 'code', value: this.code },
              { name: 'contactName', value: this.contactName },
              { name: 'tenants', value: '' }
            ]
          })
        // console.log('newTenantGroup:', newTenantGroup)
        await this.$store.dispatch('addUserToTenantGroup', {
          tenantGroupId: newTenantGroup.data.id,
          entityId: newTenantGroup.data.id,
          role: 'TenantGroupAdmin',
          username: this.userName
        })

        this.$store.commit('showMessage', { message: `Company Group ${this.name} successfully added`, color: 'success' })
        this.$emit('add')
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
        console.log('Error:', error)
      }
    }
  }
}
</script>
