<template>
  <v-btn
    fab
    small
    depressed
    @click="deleteEntity"
  >
    <v-icon color="error">
      mdi-delete-outline
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    tenantGroupId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    deleteEntity () {
      const message = `Are you sure you want to delete ${this.name}?`
      this.$confirm(message, { title: 'Delete Company Group', buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$store
              .dispatch('deleteTenantGroup', { id: this.tenantGroupId })
              .then(() => {
                this.$emit('delete')
                const message = `${this.name} deleted successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
                this.$store.dispatch('fetchEntities')
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              })
          }
        })
    }
  }
}
</script>
