<template>
  <v-card
    max-width="1200"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-card-title class="pb-0">
            {{ title }}
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                @click="saveChanges"
              >
                <v-icon left>
                  mdi-check-circle-outline
                </v-icon>
                {{ $t('Save Changes') }}
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="name"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Name')"
                    :rules="nameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="description"
                    prepend-icon="mdi-comment-text-outline"
                    :label="$t('Description')"
                    :rules="descriptionRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="erpCode"
                    prepend-icon="mdi-qrcode"
                    :label="$t('ERP Code')"
                    :rules="erpCodeRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-text-field
                    v-model="address"
                    prepend-icon="mdi-map-marker-outline"
                    :label="$t('Address')"
                    :rules="addressRules"
                    outlined
                    dense
                  />
                </div>
                <div class="subtitle-1">
                  <v-text-field
                    v-model="contactName"
                    prepend-icon="mdi-account-circle-outline"
                    :label="$t('Contact Name')"
                    :rules="contactNameRules"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <inputTel
                    v-model="contactPhone"
                    prepend-icon="mdi-phone-outline"
                    :label="$t('Contact Phone')"
                    :rules="contactPhoneRules"
                    outlined
                    dense
                  />
                </div>
              </v-col>
              <v-col>
                <div class="my-2 subtitle-1">
                  <language-input
                    v-model="locale"
                    :label="$t('Language')"
                    prepend-icon="mdi-translate"
                    outlined
                    dense
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="currency"
                    :menu-props="{offsetY:true}"
                    :items="currencies"
                    :label="$t('Currency')"
                    prepend-icon="mdi-currency-usd-circle-outline"
                    item-text="displayName"
                    return-object
                    outlined
                    dense
                    :rules="currencyRules"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="height"
                    :menu-props="{offsetY:true}"
                    :items="heights"
                    :label="$t('Height')"
                    prepend-icon=" mdi-ruler"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                    :rules="heightRules"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="temperature"
                    :menu-props="{offsetY:true}"
                    :items="temperatures"
                    :label="$t('Temperature')"
                    prepend-icon="mdi-thermometer"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                    :rules="temperatureRules"
                  />
                </div>
                <div class="my-2 subtitle-1">
                  <v-select
                    v-model="volume"
                    :menu-props="{offsetY:true}"
                    :items="volumes"
                    :label="$t('Volume')"
                    prepend-icon="mdi-oil-temperature"
                    item-text="symbol"
                    return-object
                    outlined
                    dense
                    :rules="volumeRules"
                  />
                </div>
              </v-col>
              <v-col cols="12">
                <v-card-title class="pb-2 text-subtitle-1 font-weight-bold">
                  {{ $t('MiSite Settings') }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="my-2 subtitle-1">
                        <v-text-field
                          v-model="misiteUsername"
                          prepend-icon="mdi-account-circle-outline"
                          :label="$t('Username')"
                          outlined
                          dense
                        />
                      </div>
                      <div class="my-2 subtitle-1">
                        <v-text-field
                          v-model="password"
                          prepend-icon="mdi-form-textbox-password"
                          :label="$t('Password')"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          outlined
                          dense
                          @click:append="showPassword = !showPassword"
                        />
                      </div>
                    </v-col>
                    <v-col>
                      <div class="my-2 subtitle-1">
                        <v-text-field
                          v-model="alarmDuration"
                          prepend-icon="mdi-alarm"
                          :label="$t('Minimum Alarm Duration')"
                          outlined
                          dense
                          :rules="alarmDurationRules"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageInput from '@/components/common/LanguageInput.vue'
import InputTel from '@/components/common/InputTel.vue'

export default {
  name: 'NewTenantCard',
  components: {
    LanguageInput,
    InputTel
  },
  props: {
    tenantGroupId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      newTenantId: null,
      valid: false,
      title: 'New Company',
      name: '',
      description: '',
      erpCode: '',
      address: '',
      contactName: '',
      contactPhone: '',
      height: {},
      volume: {},
      temperature: {},
      currency: {},
      locale: {},
      alarmDuration: 0,
      alarmDurationRules: [
        v => (v >= 0 && v <= 15) || 'Duration must be between 0-15 minutes',
        v => !!v || 'Please fill in this field'
      ],
      nameRules: [v => !!v || 'Please fill in this field'],
      descriptionRules: [],
      erpCodeRules: [],
      addressRules: [],
      contactPhoneRules: [],
      contactNameRules: [],
      dataSource: 'PPX',
      password: '',
      showPassword: false,
      misiteUsername: '',
      localeRules: [v => !!v || 'Please fill in this field'],
      currencyRules: [v => !!v || 'Please fill in this field'],
      temperatureRules: [v => !!v || 'Please fill in this field'],
      volumeRules: [v => !!v || 'Please fill in this field'],
      heightRules: [v => !!v || 'Please fill in this field'],
      languageRules: [v => !!v || 'Please fill in this field']
    }
  },
  computed: {
    ...mapGetters(['currencies', 'volumes', 'heights', 'temperatures', 'locales'])
  },
  methods: {
    saveChanges () {
      if (!this.$refs.form.validate()) {
        return null
      }
      this.$store
        .dispatch('createTenant', {
          tenantName: this.name,
          description: this.description,
          tenantGroupId: this.tenantGroupId,
          attributes: [
            { name: 'erpCode', value: this.erpCode },
            { name: 'contactName', value: this.contactName },
            { name: 'address', value: this.address },
            { name: 'contactPhone', value: this.contactPhone },
            { name: 'heightCode', value: this.height.code },
            { name: 'volumeCode', value: this.volume.code },
            { name: 'temperatureCode', value: this.temperature.code },
            { name: 'currencyCode', value: this.currency.code },
            { name: 'locale', value: this.locale ? this.locale.value : null },
            { name: 'siteDataSourceId', value: this.dataSource },
            { name: 'username', value: this.misiteUsername },
            { name: 'alarmDuration', value: this.alarmDuration },
            { name: 'password', value: this.password }
          ]
        })
        // .then((id) => {
        //   this.newTenantId = id
        //   console.log('[newTenactAdded] id:', id)
        //   this.$store.dispatch('addTenantToTenantGroup', {
        //     newTenantId: id
        //   })
        //     .then(() => {
        //       this.$store.dispatch('editEntity', {
        //         entityId: this.newTenantId,
        //         name: this.name,
        //         description: this.description,
        //       })
        //     })
        // })
        .then((id) => {
          console.log('Tenant created. id:', id)
          this.$store.commit('showMessage', { message: `Company ${this.name} added successfully`, color: 'success' })
          this.$emit('add', id)
        })
        .catch((error) => {
          this.$store.commit('showMessage', { message: error, color: 'error' })
        })
    }
  }
}
</script>
