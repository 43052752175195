<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-btn
            fab
            depressed
            :disabled="disabled"
            x-small
            :loading="buttonLoading"
            @click="buttonClick()"
          >
            <v-icon>
              mdi-link-variant
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>Link</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ `Link Users` }}</span>
          </v-card-title>
          <v-card-text class="pr-16">
            <v-container pr-16>
              <v-row class="pr-10">
                <v-autocomplete
                  v-model="selectedUsers"
                  :items="optionalUsers"
                  :disabled="selectedUsersDisabled"
                  outlined
                  :rules="userRules"
                  item-text="username"
                  label="Selected System Users"
                  multiple
                />
              </v-row>
              <v-row class="pr-10">
                <v-autocomplete
                  v-model="selectedRoles"
                  :items="tenantGroupsRoles"
                  :rules="roleRules"
                  outlined
                  label="Selected Roles"
                  multiple
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!(selectedUsers.length && selectedRoles.length)"
              @click="link"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
// import Treeselect from '@/components/common/Treeselect.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    // Treeselect
  },
  props: {
    usernames: {
      type: Array,
      default: () => []
    },
    tenentGroupId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      valid: false,
      dialog: false,
      key: 0,
      selectedUsers: [],
      // TODO: remove this?
      selectedEntities: [],
      optionalUsers: [],
      selectedRoles: [],
      filteredUsers: [],
      buttonLoading: false,
      selectedUsersDisabled: false,
      userRules: [v => (v ?? []).length > 0 || 'Please select a user'],
      roleRules: [v => (v ?? []).length > 0 || 'Please select a role']
    }
  },
  computed: {
    ...mapGetters(['users', 'readOnlyUsers', 'tenantGroupsRoles', 'entities', 'userName'])
  },
  methods: {
    async buttonClick () {
      this.buttonLoading = true

      if (this.usernames.length) {
        this.selectedUsers = [...this.usernames]
        this.selectedUsersDisabled = true
        this.optionalUsers = this.selectedUsers
      } else {
        this.selectedUsersDisabled = false
        const tenant = 'system'
        if (!(this.users && this.users[tenant])) {
          await this.$store.dispatch('fetchUsers', tenant)
        }
        const optionalUsersReadWrite = (this.users.system) ? this.users.system.filter(el => el.username !== this.userName) : []
        const optionalUsersReadOnly = (this.readOnlyUsers.system) ? this.readOnlyUsers.system.filter(el => el.username !== this.userName) : []
        console.log('optionalUsersReadOnly', optionalUsersReadOnly)
        this.optionalUsers = [...optionalUsersReadWrite, ...optionalUsersReadOnly]
      }
      this.buttonLoading = false
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.key += 1
      this.selectedUsers = []
      this.selectedRoles = []
    },
    async link () {
      if (!this.$refs.form.validate()) {
        return null
      }
      try {
        for (const username of this.selectedUsers) {
          for (const role of this.selectedRoles) {
            console.log('tenentGroupId', this.tenentGroupId, 'role:', role, 'username:', username)
            await this.$store.dispatch('addUserToTenantGroup', {
              tenantGroupId: this.tenentGroupId,
              entityId: this.tenentGroupId,
              role,
              username
            })
          }
        }
        this.cancel()
        this.$emit('change')
        const message = 'Changes were done successfully'
        this.$store.commit('showMessage', { message, color: 'success' })
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    }
  }
}
</script>
