
<template>
  <v-card
    max-width="1200"
    flat
    class="pa-0 ma-0"
  >
    <v-card-title class="pb-2">
      {{ $t("User Directory") }}
      <v-spacer />
      <link-button
        v-if="isSystemAdmin"
        :tenent-group-id="id"
        @change="getUsersPerTenantGroup()"
      />
    </v-card-title>
    <v-divider />
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        prepend-icon="mdi-feature-search-outline"
        :label="$t('Search')"
        single-line
        hide-details
        dense
        :loading="loading"
      />
    </v-card-subtitle>
    <v-list-item
      v-for="(item, i) in filteredItems"
      :key="i"
    >
      <v-list-item-avatar>
        <v-avatar
          :color="randomColor(i)"
          size="64"
          class="white--text"
        >
          {{ item.name.charAt(0).toUpperCase() }}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <strong>{{ item.name }}</strong>
          @{{ item.username }}
        </v-list-item-title>
        <v-chip-group>
          <v-chip
            v-for="(role, index) in item.roles"
            :key="index"
            outlined
            pill
            :close="canDeleteRole(item, role, id)"
            @click:close="deleteRole(role, item)"
          >
            {{ role }}
          </v-chip>
        </v-chip-group>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import LinkButton from '@/components/tenant-groups/TenantGroupButtonLink.vue'
import { arrayToObject } from '@/common/utilities'
import _ from 'lodash'
export default {
  components: {
    LinkButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    canEditTenant: {
      type: Boolean,
      required: true
    },
    isTenantGroup: {
      type: Boolean,
      required: false
    },
    isSystemAdmin: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    users: [],
    search: '',
    key: 0,
    loading: true,
    colors: ['#cdb4db', '#ffafcc', '#a2d2ff', '#cdeac0']
  }),
  computed: {
    filteredItems () {
      if (!this.users) return
      return _.orderBy(this.users.filter(item => {
        if (!this.search) {
          return this.users
        }
        return (item.username.toLowerCase().includes(this.search.toLowerCase()))
      }), 'username')
    }
  },
  async created () {
    await this.getUsersPerTenantGroup()
  },
  methods: {
    canDeleteRole (item, role, entity) {
      if (this.isTenantGroup) { return this.canEditTenant }
      if (!item.explicit_roles || !item.explicit_roles.includes(role)) {
        return false
      }
      return this.canEditTenant
    },
    randomColor (index) {
      return this.colors[index % this.colors.length]
    },
    async getUsersPerTenantGroup () {
      this.users = []
      this.loading = true
      const data = await this.$store.dispatch('getUsersPerTenantGroup', this.id)
      for (const user of data) {
        this.users.push({
          ...this.parseUser(user)
        })
      }
      this.key += 1
      this.loading = false
    },
    parseUser (user) {
      const attrs = arrayToObject(user.user.attributes)
      user = {
        username: user.user.name,
        roles: user.roles,
        ...user.user,
        ...attrs
      }
      delete user.attributes
      return user
    },
    deleteRole (role, item) {
      const message = `Are you sure you want to delete ${role} from ${item.name}?`
      this.$confirm(message, { title: 'Delete Role from User', buttonTrueText: 'Delete', buttonFalseText: 'Cancel', color: 'primary', icon: '' })
        .then(res => {
          if (res) {
            this.$store
              .dispatch('deleteRoleFromUserOnTenantGroup', {
                tenantGroupId: this.id,
                entityId: this.id,
                role,
                username: item.username
              })
              .then(() => {
                const message = `${role} role was deleted successfully`
                this.$store.commit('showMessage', { message, color: 'success' })
                this.getUsersPerTenantGroup()
              })
              .catch(error => {
                this.$store.commit('showMessage', { message: error, color: 'error' })
              })
          }
        })
    }
  }
}
</script>
