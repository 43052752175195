<template>
  <v-card>
    <div>
      <v-card-title
        class="primary white--text headline"
      >
        <v-text-field
          v-model="search"
          class="ml-5"
          :label="$t('Search Company Directory')"
          prepend-inner-icon="mdi-feature-search-outline"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
      </v-card-title>
      <div v-if="loading">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </div>
      <div v-if="!loading">
        <div
          v-if="!loading && tenantGroupsListFull && !tenantGroupsListFull.length"
          class="ml-2"
        >
          <br>Welcome!
          <br>Companies are not yet mapped for you.
        </div>
        <v-row
          v-else
          class="pa-4"
          justify="space-between"
        >
          <v-col cols="3">
            <v-btn
              v-if="canCreateTenantGroup"
              class="ml-3"
              depressed
              small
              @click="createNewTenantGroup()"
            >
              <v-icon small>
                mdi-plus
              </v-icon>
              New Company Group
            </v-btn>
            <v-divider
              v-if="canCreateTenantGroup"
              class="mt-4"
            />
            <v-treeview
              :active.sync="active"
              :items="tenantGroupsListFull"
              :open.sync="open"
              :search="search"
              :filter="filter"
              item-children="child_entities"
              item-text="entity.name"
              item-key="entity.unique"
              activatable
              loading-icon
              color="warning"
              transition
              return-object
              @update:active="selectionChange"
            >
              <template v-slot:append="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-btn
                        v-if="item.entity.entity_type === 'TENANT_GROUP' && item.canCreateTenant"
                        x-small
                        fab
                        icon
                        @click="createNewTenant(item)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Create Company</span>
                </v-tooltip>
              <!-- <v-btn
                v-if="item.entity.entity_type === 'TENANT'"
                small
              >
                Remove from Group
              </v-btn> -->
              <!-- <add-entity-dialog
                v-if="item.entity.entity_type !== 'SITE' && item.roles.includes('EntityAdmin')"
                :entity-id="item.entity.id"
                @add="fetchEntities()"
              /> -->
              </template>
            </v-treeview>
          </v-col>

          <v-divider
            vertical
            class="ma-0 pa-0"
          />

          <v-col cols="8">
            <v-scroll-y-transition mode="out-in">
              <new-tenant-group-card
                v-if="newTenantGroup && !newTenant && !infoSelected"
                @add="newTenantGroupAdded"
              />
              <new-tenant-card
                v-if="newTenant && !newTenantGroup && !infoSelected"
                :tenant-group-id="selectedTenantGroup"
                @add="newTenantAdded($event)"
              />
              <div
                v-if="!active.length && !newTenant && !newTenantGroup && !infoSelected"
                class="title grey--text text--lighten-1 font-weight-light"
                style="align-self: center;"
              >
                {{ $t("Select a Company Group") }}
              </div>
              <v-card
                v-if="active.length && infoSelected"
                :key="selected.entity.id"
                class="mx-auto"
                max-width="1200"
                flat
              >
                <v-card-text>
                  <tenant-group-info-card
                    v-if="selected.entity.entity_type === 'TENANT_GROUP'"
                    :id="selected.entity.id"
                    :is-system-admin="canCreateTenantGroup"
                    :user-name="userName"
                    @change="newTenantAdded()"
                    @delete="tenantDeleted()"
                  />
                  <tenant-info-card
                    v-if="selected.entity.entity_type === 'TENANT'"
                    :id="selected.entity.id"
                    :tenant-id="selected.entity.id"
                    :user-name="userName"
                    :tenant-group-id="selected.entity.treeSelectedTenantGroup"
                    @delete="tenantDeleted()"
                  />
                </v-card-text>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TenantGroupInfoCard from '@/components/tenant-groups/TenantGroupCardInfo.vue'
import TenantInfoCard from '@/components/tenants/TenantCardInfo.vue'
import NewTenantGroupCard from '@/components/tenant-groups/TenantGroupCardNew.vue'
import NewTenantCard from '@/components/tenants/TenantCardNew.vue'
import * as _ from 'lodash'
import { createUniqueId } from '@/common/utilities'
export default {
  components: {
    NewTenantGroupCard,
    TenantInfoCard,
    TenantGroupInfoCard,
    NewTenantCard
  },
  data: () => ({
    newTenantGroup: false,
    selectedTenantGroup: null,
    tenantGroupsListFull: [],
    newTenant: false,
    infoSelected: false,
    caseSensitive: false,
    search: null,
    active: [],
    avatar: null,
    open: [],
    loading: false,
    items: [],
    canEditEntity: false,
    canDeleteEntity: false,
    canCreateTenantGroup: false
  }),
  computed: {
    ...mapGetters(['tenantGroupsList', 'tenantsList', 'userName', 'currentTenantId']),
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    selected () {
      if (!this.active.length) return undefined
      return this.active[0]
    }
  },
  watch: {
    active () {
      this.newTenantGroup = false
      // this.newTenant = false
    },
    currentTenantId: {
      immediate: false,
      handler () {
        if (this.currentTenantId !== 'system') this.$router.push('/sites')
      }
    }
  },
  async created () {
    if (this.currentTenantId !== 'system') {
      this.$router.push('/sites')
    } else {
      await this.fetchTenantsList()
      await this.fetchTenantGroups()
      await this.setPermissions()
      this.loading = false
    }
  },
  methods: {
    async canCreateTenant (tenantGroupId) {
      try {
        const canCreateTenant = await this.enforce(tenantGroupId, 'tenant-create', this.userName, tenantGroupId)
        return canCreateTenant
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    },
    async setPermissions () {
      try {
        this.canCreateTenantGroup = await this.enforce('tenant-registry', 'tenant-group-create', this.userName, 'system')
      } catch (error) {
        this.$store.commit('showMessage', { message: error, color: 'error' })
      }
    },
    async enforce (entity, action, userName, tenantId) {
      this.loading = true
      const enforce = await this.$store.dispatch('enforce', {
        userName,
        tenantId,
        entity,
        action
      })
      this.loading = false
      return enforce
    },
    createNewTenantGroup (item) {
      this.newTenantGroup = true
      this.newTenant = false
      setTimeout(() => {
        this.infoSelected = false
      }, 100)
    },
    createNewTenant (item) {
      this.selectedTenantGroup = item.entity.id
      this.newTenant = true
      this.newTenantGroup = false
      // removes info selection
      setTimeout(() => {
        this.infoSelected = false
      }, 100)
    },
    async newTenantGroupAdded () {
      this.loading = true
      await this.fetchTenantGroups()
      this.newTenantGroup = false
      this.active = []
      // this.selectedTenantGroup = null
      this.loading = false
    },
    async tenantDeleted () {
      this.loading = true
      await this.fetchTenantsList()
      await this.fetchTenantGroups()
      this.newTenant = false
      this.active = []
      this.loading = false
    },
    async newTenantAdded (id) {
      this.loading = true
      // console.log('[newTenactAdded] id:', id)
      // await this.$store.dispatch('addTenantToTenantGroup', {
      //   tenantGroupId: this.selectedTenantGroup,
      //   newTenantId: id
      // })
      await this.fetchTenantsList()
      await this.fetchTenantGroups()
      this.newTenant = false
      // this.active = []
      this.selectedTenantGroup = null
      this.loading = false
    },
    async fetchTenantsList () {
      this.loading = true
      await this.$store.dispatch('fetchTenantsList')
      this.loading = false
    },
    async fetchTenantGroups () {
      this.loading = true
      await this.$store.dispatch('fetchTenantGroups')
      if (this.tenantGroupsList) {
        const tenantGroupsListWithParents = await this.addTenantParams(this.tenantGroupsList)
        this.tenantGroupsListFull = tenantGroupsListWithParents
      }
      this.loading = false
    },
    async addTenantParams (tgl) {
      var allTenantGroup = {
        child_entities: [],
        entity: {
          name: 'ALL',
          entity_type: 'TENANT_GROUP',
          tenant_id: '1',
          attributes: [{ name: 'nnnn', value: 'vvvv' }, { name: 'tenants', value: '' }],
          description: '',
          id: '1',
          creation_date: ''
        }
      }
      var usedIds = []
      await Promise.all(tgl.map(async tg => {
        tg.canCreateTenant = await this.canCreateTenant(tg.entity.id)
        for (var t of tg.child_entities) {
          t.entity.treeSelectedTenantGroup = tg.entity.id
          if (!usedIds.includes(t.entity.id)) {
            allTenantGroup.child_entities.push(_.cloneDeep(t))
            usedIds.push(t.entity.id)
          }
        }
      }))
      const allIds = allTenantGroup.child_entities.map(el => el.entity.id)
      for (const tenant of this.tenantsList.map(function (el) { return { entity: el } })) {
        if (!allIds.includes(tenant.entity.id) && tenant.entity.id !== 'system') {
          allTenantGroup.child_entities.push(tenant)
        }
      }
      tgl.push(allTenantGroup)

      // assign unique ids
      for (const tg of tgl) {
        tg.entity.unique = createUniqueId()
        for (var t of tg.child_entities) {
          t.entity.unique = createUniqueId()
        }
      }
      return tgl
    },
    selectionChange (active) {
      if (active.length > 0) {
        this.infoSelected = true
      } else {
        this.infoSelected = false
      }
    }
  }
}
</script>
